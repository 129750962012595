<template>
  <form class="q-px-lg" @submit.prevent.stop="">
    <div class="row col-12 justify-around q-pb-sm">
      <div class="col-5">
        <div>
          <span>Categoria</span>
        </div>
        <div class="row">
          <Select
            :options="optionscategory"
            :listOption="listcategory"
            :isMultiple="false"
            label="Categorias"
            @selectValue="(val) => (formPostReport.Category = val)"
          />
        </div>
      </div>

      <div class="col-5">
        <div>
          <span>Grupos</span>
        </div>
        <div class="row">
          <Select
            :options="optionsGroups"
            :listOption="listGroups"
            :isMultiple="false"
            label="Grupos"
            @selectValue="(val) => (formPostReport.Groups = val)"
          />
        </div>
      </div>
    </div>

    <div class="row col-12 justify-around q-pb-sm">
      <div class="col-5">
        <div>
          <span>Criador por: </span>
        </div>
        <div class="row">
          <Select
            :options="optionsCreatorWiki"
            :listOption="listCreatorWiki"
            :isMultiple="false"
            label="Usuários"
            @selectValue="(val) => (formPostReport.Users = val)"
          />
        </div>
      </div>

      <div class="col-5"></div>
    </div>
    <hr class="q-mt-xl q-mb-md" />

    <div class="q-gutter-sm flex row q-ml-xl">
      <div v-for="time in period" :key="time.label">
        <q-radio
          v-model="periodSelect"
          :val="time.label"
          @update:model-value="() => setDataByPeriod(time)"
          :label="time.label"
          color="default-pink"
        />
      </div>
    </div>

    <div class="row col-12 justify-between q-pb-sm q-ml-xl">
      <div class="col-7 flex justify-between">
        <!-- <div class="date-input-sm">
        <div>
          <span>Período</span>
        </div>
        <div class="row">
          <q-select
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="periodSelect"
            :options="period"
            @update:model-value="(period) => setDataByPeriod(period)"
          />
        </div>
      </div> -->
        <div class="date-input-md">
          <div>
            <span>De</span>
          </div>
          <div class="row">
            <q-input
              type="date"
              aria-required="true"
              dense
              class="col-12"
              outlined
              color="default-pink"
              bg-color="grey-3"
              v-model="formPostReport.Date_Start"
            />
          </div>
        </div>

        <div class="date-input-md">
          <div>
            <span>Até</span>
          </div>
          <div class="row">
            <q-input
              type="date"
              aria-required="true"
              dense
              class="col-12"
              outlined
              color="default-pink"
              bg-color="grey-3"
              :rules="[
                (val) =>
                  val >= formPostReport.Date_Start ||
                  'Por favor selecione uma data pós o dia inicial',
              ]"
              v-model="formPostReport.Date_End"
            />
          </div>
        </div>
      </div>

      <div class="col flex justify-end items-center q-mr-sm">
        <q-btn
          class="submit-btn"
          unelevated
          color="default-pink"
          @click="validateForm() ? $emit('sendForm', formPostReport) : null"
        >
          <q-img class="q-mr-sm icon-btn" :src="filterimg" width="18px" />
          <div>Filtro</div>
        </q-btn>
      </div>
    </div>
    <!-- <footer class="q-py-md">
      <div class="row justify-center">
        <q-btn
          class="q-ml-sm submit-btn"
          unelevated
          outline
          color="default-pink"
          @click="
            validateForm()
              ? $emit('sendForm', { formData: formPostReport, id: 1 })
              : null
          "
        >
          <q-icon class="q-mr-sm" name="o_file_download" size="20px" />
          <div>Baixar excel</div>
        </q-btn>
      </div>
    </footer> -->
  </form>
</template>

<script>
//----IMAGES
import filterimg from "@/assets/icons/filter.svg";

//----VUEJS
import { reactive, ref } from "vue";

//----QUASAR
import { useQuasar } from "quasar";

//----COMPONENTS
import Select from "@/components/reports/inputs/Select.vue";
import SelectSearch from "@/components/reports/inputs/SelectSearch.vue";

export default {
  name: "WikiHistoryForm",
  components: {
    Select,
    SelectSearch,
  },
  props: {
    listGroups: Array,
    listcategory: Array,
    listCreatorWiki: Array,
    getPostByTitle: Function,
  },

  emits: ["sendForm"],

  setup(props) {
    //------CONSTATNS
    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },

      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);
    const $q = new useQuasar();

    //------VARIABLES
    let formPostReport = reactive({
      Category: null,
      Groups: null,
      Users: null,
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
    });

    let periodSelect = ref(null);

    let optionsGroups = ref(props.listGroups);
    let optionsCreatorWiki = ref(props.listCreatorWiki);
    let optionscategory = ref(props.listcategory);
    let optionsPost = ref([]);

    function filterSelect(val, update, options, list) {
      if (val === "") {
        update(() => {
          options.value = list.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options.value = list.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function formatItem(item) {
      return {
        label: item.title,
        value: item.title,
      };
    }

    function addSelect(formValue, currentValue) {
      formValue = currentValue;
    }

    async function filterPost(val, update) {
      return await props.getPostByTitle(val);
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formPostReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formPostReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formPostReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formPostReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formPostReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formPostReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formPostReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formPostReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formPostReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formPostReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    function validateForm() {
      // if (formPostReport.Category.length < 1) {
      //   _alertOf("Categoria é obrigatória");
      //   return false;
      // } else if (formPostReport.CreatePost.length < 1) {
      //   _alertOf("Criador de post é obrigatório");
      //   return false;
      // } else if (formPostReport.Lider.length < 1) {
      //   _alertOf("Lider é obrigatório");
      //   return false;
      // } else if (formPostReport.Users.length < 1) {
      //   _alertOf("Usuário é obrigatório");
      //   return false;
      // }

      return true;
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      //-----IMAGES
      filterimg,

      //------CONSTATNS
      period,

      //------VARIABLES
      formPostReport,
      optionsGroups,
      optionsCreatorWiki,
      optionscategory,
      optionsPost,
      periodSelect,

      //------FUNCTIONS
      filterSelect,
      addSelect,
      filterPost,
      setDataByPeriod,
      validateForm,
      formatItem,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-input-sm {
  width: 35%;
}
.date-input-md {
  width: 30%;
}

.icon-btn {
  filter: brightness(2.5);
}
.submit-btn {
  border-radius: 8px;
  padding: 9px 64px;

  .text-btn {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    font-variant: small-caps;
  }
}
</style>
