<template>
  <div>
    <TitleButton
      @stateButton="
        (isShowFilter) => {
          flag = isShowFilter;
        }
      "
    />
    <PostHistoryForm
      :listGroups="listGroups"
      :listcategory="listCategories"
      :listCreatorPost="listCreatorPost"
      :getPostByTitle="getPostByTitle"
      @sendForm="(form) => $emit('historyList', () => validateForm(form))"
      v-if="flag"
    />
    <div class="table-feed">
      <h5 class="q-mt-sm q-ml-xl">Histórico de posts</h5>
      <Table
        class="full-height full-width"
        :list="listHistoryData"
        :columns="columnsTable"
        :addEdit="false"
        :addRemove="false"
      >
      </Table>
    </div>
  </div>
</template>

<script>
//----COMPONENTS
import PostHistoryForm from "@/components/reports/history/forms/PostHistoryForm.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import RoleServices from "@/services/RoleServices";
import CompanyServices from "@/services/CompanyServices";
import PostDataServices from "@/services/PostDataServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "FeedPage",

  components: {
    PostHistoryForm,
    Table,
    TitleButton,
  },
  emits: ["historyList"],
  setup() {
    //------SERVICES
    const _RoleService = new RoleServices();
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _companyServices = new CompanyServices();
    const _postDataServices = new PostDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------VARIABLES
    let listCompanies = ref([]);
    let listUsers = ref([]);
    let listCreatorPost = ref([]);
    let listProfiles = ref([]);
    let listGroups = ref([]);
    let listCategories = ref([]);
    let listHistoryData = ref([]);
    let flag = ref();

    /* CONSTANTS*/

    const columnsTable = ref([
      {
        name: "post_id",
        align: "center",
        label: "ID",
        field: (row) => row.post_id,
        sortable: true,
      },
      {
        name: "tutor_name",
        required: true,
        label: "Postado por",
        align: "center",
        field: (row) => row.tutor_name,
        sortable: false,
      },
      {
        name: "created_at",
        align: "center",
        label: "Postado em",
        field: (row) => row.created_at,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
        sortable: false,
      },
      {
        name: "groups_name",
        align: "center",
        label: "Grupo",
        field: (row) => row.groups_name,
        sortable: false,
      },
      {
        name: "title",
        align: "center",
        label: "Título",
        field: (row) => row.title,
        sortable: false,
      },
      {
        name: "desc_category",
        label: "Categoria",
        align: "center",
        field: (row) => row.desc_category,
      },
    ]);

    onMounted(() => {
      _getAllRoles();
      _getAllGroups();
      _getAllUsers();
      _getAllCompanies();
      _getAllCategories();
    });

    async function _getAllRoles() {
      _RoleService.showRoles().then((response) => {
        response.data.map((item) => {
          listProfiles.value.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    }

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2 || item.role_id == 1) {
              listCreatorPost.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }

            listUsers.value.push({
              label: item.first_name + " " + item.last_name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCompanies() {
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      await _postDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.description,
              value: item.id,
            });
          });
        });
    }

    async function getPostByTitle(title) {
      const PAGE_SIZE = 50;
      const PAGE_NUMBER = 1;
      return await _postDataServices.getPostsByTitle(
        PAGE_NUMBER,
        PAGE_SIZE,
        title
      );
    }

    async function validateForm(form) {
      const formData = new FormData();

      if (form.formData.Groups != null) {
        formData.append("Groups_Id", form.formData.Groups);
      }

      if (form.formData.Category != null) {
        formData.append("Category_Id", form.formData.Category);
      }
      if (form.formData.Post != null) {
        formData.append("title", form.formData.Post);
      }

      if (form.formData.Users != null) {
        formData.append("UserId", form.formData.Users);
      }

      formData.append("dateInitial", form.formData.Date_Start);
      formData.append("dateFinal", form.formData.Date_End);

      await _postDataServices
        .postHistory(formData)
        .then((response) => {
          listHistoryData.value = response.data;
          if (listHistoryData.value.length === 0) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          }
        })
        .catch((err) => {
          Alerts.alertOf("Erro ao gerar relatório, por favor tente mais tarde");
        });

      return listHistoryData.value;
    }

    return {
      //------VARIABLES
      listCompanies,
      listUsers,
      listProfiles,
      listGroups,
      listCategories,
      listCreatorPost,
      listHistoryData,
      flag,

      // CONSTANTS
      columnsTable,

      //------FUNCTIONS
      getPostByTitle,
      validateForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}

.table-feed {
  margin-top: 5vh;
}
</style>
