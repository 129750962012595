<template>
  <TitleButton
    @stateButton="
      (isShowFilter) => {
        flag = isShowFilter;
      }
    "
  />
  <MoodHistoryForm
    v-if="flag"
    @tableData="
      (data) => {
        rows = data;
      }
    "
  />
  <div class="table-mood">
    <h5 class="q-mt-sm q-ml-xl">Humor</h5>
    <q-table
      :rows="rows"
      :rows-per-page-options="[5]"
      :columns="columns"
      :filter="filter"
      row-key="name"
      style="box-shadow: none"
      color="default-pink"
      virtual-scroll
      class="full-width text-center"
    >
      <template v-slot:top-right>
        <div>
          <q-input
            class="col-3 input-search q-ma-sm"
            bg-color="white"
            color="default-pink"
            dense
            type="text"
            placeholder="Pesquisar"
            outlined
            rounded
            v-model="filter"
          >
            <template v-slot:prepend>
              <q-icon name="search" color="default-pink" />
            </template>
          </q-input>
        </div>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="user_name" :props="props">
            <span class="moodTitle"> {{ props.row.user_name }}</span>
          </q-td>
          <q-td key="start_date" :props="props">
            <span class="moodTitle">
              {{
                props.row.start_date
                  ?.slice(0, 10)
                  .split("-")
                  .reverse()
                  .join("/")
              }}</span
            >
          </q-td>
          <q-td key="end_date" :props="props">
            <span class="moodTitle">
              {{
                props.row.end_date?.slice(0, 10).split("-").reverse().join("/")
              }}</span
            >
          </q-td>
          <q-td key="title" :props="props">
            <span class="moodTitle"> {{ props.row.title }}</span>
          </q-td>
          <q-td key="groups_name" :props="props">
            <span class="moodTitle"> {{ props.row.groups_name }}</span>
          </q-td>
          <q-td key="emoji_path" :props="props">
            <q-img
              :src="urlAwsBucket + props.row.emoji_path"
              style="width: 50px; height: 50px; border-radius: 50%"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import MoodHistoryForm from "@/components/reports/history/forms/MoodHistoryForm.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";
import { ENV  } from "@/utils/env";

import { ref } from "vue";
export default {
  name: "MoodPage",
  components: {
    MoodHistoryForm,
    TitleButton,
  },
  setup() {
    const flag = ref(false);
    const filter = ref("");
    const urlAwsBucket =ENV.URL_AWS_BUCKET;
    const columns = ref([
      { name: "user_name", label: "Usuário", field: (rows) => rows.user_name },
      {
        name: "start_date",
        label: "Data Inicial",
        field: (rows) => rows.start_date,
      },
      {
        name: "end_date",
        label: "Data Final",
        field: (rows) => rows.start_date,
      },
      { name: "title", label: "Título", field: (rows) => rows.title },
      {
        name: "groups_name",
        label: "Grupos",
        field: (rows) => rows.groups_name,
      },
      { name: "emoji_path", label: "Ícone", field: (rows) => rows.emoji_path },
    ]);
    const rows = ref([]);
    return {
      flag,
      columns,
      rows,
      filter,
      urlAwsBucket,
    };
  },
};
</script>

<style scoped>
.table-mood {
  margin-top: 5vh;
}
</style>
