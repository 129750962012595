<template>
  <div>
    <TitleButton
      @stateButton="
        (isShowFilter) => {
          flag = isShowFilter;
        }
      "
    />
    <CourseForm
      :listCourse="listCourse"
      :listTrail="listTrail"
      :listCreatorCourse="listTutor"
      :listcategory="listCategories"
      @getCourseId="(course) => getCourse(course)"
      @sendForm="(form) => validateForm(form)"
      v-if="flag"
    />
    <div class="table-course">
      <h5 class="q-mt-sm q-ml-xl">Histórico de cursos</h5>
      <Table
        class="full-height full-width"
        :list="row"
        :columns="columnsTable"
        :addEdit="false"
        :addRemove="false"
      >
      </Table>
    </div>
  </div>
</template>

<script>
//----COMPONENTS
import CourseForm from "@/components/reports/history/forms/CourseHistoryForm.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";

//----SERVICES
import UserServices from "@/services/UserServices";
import TrailDataServices from "@/services/TrailDataServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

//----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "CoursePage",

  components: {
    CourseForm,
    Table,
    TitleButton,
  },
  setup() {
    //------SERVICES
    const _UserService = new UserServices();
    const _trailDataServices = new TrailDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();
    const company_id = store.state.userdata.company_id;
    //----UTILS
    const Alerts = new AlertsClass();

    //------CONSTATNS
    const $q = new useQuasar();
    const columnsTable = [
      {
        name: "course_id",
        label: "ID",
        align: "center",
        sortable: true,
        field: (row) => row.course_id,
      },
      {
        name: "title",
        label: "Nome",
        align: "center",
        field: (row) => row.title,
      },
      {
        name: "created_name",
        label: "Criado por",
        align: "center",
        field: (row) => row.created_name,
      },
      {
        name: "number_trainings",
        label: "Trilhas",
        align: "center",
        field: (row) => row.number_trainings,
      },
      {
        name: "number_students",
        label: "Participantes",
        align: "center",
        field: (row) => row.number_students,
      },
      {
        name: "start_date",
        label: "Criação inicial",
        align: "center",
        field: (row) => row.start_date,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
      },
      {
        name: "end_date",
        label: "Criação final",
        align: "center",
        field: (row) => row.end_date,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
      },
    ];

    //------VARIABLES
    let listTutor = ref([]);
    let listCourse = ref([]);
    let listTrail = ref([]);
    let listStutendInTrail = ref([]);
    let listCategories = ref([]);
    let flag = ref();
    let row = ref([]);

    onMounted(() => {
      _getAllTutors();
      _getAllCourses();
      _getAllCategories();
    });

    async function _getAllTutors() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2) {
              listTutor.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCourses() {
      _trailDataServices
        .getAllCourses()
        .then((response) => {
          response.data.map((item) => {
            listCourse.value.push({
              label: item.title,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllTrails(courseId) {
      _trailDataServices
        .getAllTraining(courseId)
        .then((response) => {
          response.data.map((item) => {
            let trail = {
              label: item.training_title,
              value: item.id,
            };

            if (listTrail.value.length < 1) {
              listTrail.value.push(trail);
            } else if (_isInList(listTrail.value, trail)) {
              listTrail.value.push(trail);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function _isInList(List, newItem) {
      let isEqual = true;
      List.forEach((item) => {
        if (item.value == newItem.value) {
          isEqual = false;
        }
      });

      return isEqual;
    }

    async function _getAllStudentInTrails(courseId) {
      _trailDataServices
        .getStudentsInTrail(courseId)
        .then((response) => {
          response.data.map((item) => {
            let user = {
              label: item.first_name + " " + item.last_name,
              value: item.user_id,
            };
            if (listStutendInTrail.value.length < 1) {
              listStutendInTrail.value.push(user);
            } else if (_isInList(listStutendInTrail.value, user)) {
              listStutendInTrail.value.push(user);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      _trailDataServices
        .getCategories(company_id)
        .then((response) => {
          response.data.map((item) => {
            // if (item.length < 1) {
            // }

            let trail = {
              label: item.name,
              value: item.id,
            };
            listCategories.value.push(trail);
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function getCourse(courses) {
      courses.forEach(async (course) => {
        await _getAllTrails(course);
        await _getAllStudentInTrails(course);
      });
    }

    async function validateForm(form) {
      const formData = new FormData();

      /* formData.append("Company", company_id); */

      if (form.formData.Category?.length > 0) {
        formData.append("Category", form.formData.Category);
      }

      if (form.formData.Course?.length > 0) {
        formData.append("Trilha", form.formData.Course);
      }

      if (form.formData.Users?.length > 0) {
        formData.append("User_created", form.formData.Users);
      }

      formData.append("DateInitial", form.formData.Date_Start);
      formData.append("DateFinal", form.formData.Date_End);

      await _trailDataServices
        .historyCourse(formData)
        .then((response) => {
          row.value = response.data;
          if (row.value.length === 0) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          }
        })
        .catch((error) => console.log(error));
    }

    return {
      //------VARIABLES
      listTutor,
      listCourse,
      listTrail,
      listStutendInTrail,
      listCategories,
      flag,
      row,
      //------FUNCTIONS
      validateForm,
      getCourse,

      // CONSTANTS
      columnsTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}

.table-course {
  margin-top: 5vh;
}
</style>
