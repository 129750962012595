<template>
  <div>
    <TitleButton
      @stateButton="
        (isShowFilter) => {
          flag = isShowFilter;
        }
      "
    />
    <UsersForm
      :listGroups="listGroups"
      :listLider="listLider"
      :listUsers="listUsers"
      :listProfiles="listProfiles"
      :listLevels="userLevels"
      @sendForm="(form) => validateForm(form)"
      @generate="(id) => generateExcel(id)"
      v-if="flag"
    />

    <div class="table-user">
      <h5 class="q-mt-sm q-ml-xl">Histórico de usuários</h5>
      <Table
        class="full-height full-width"
        :list="userHistory"
        :columns="columnsTable"
        :addEdit="false"
        :addRemove="false"
      >
      </Table>
    </div>
  </div>
</template>

<script>
//----COMPONENTS
import UsersForm from "@/components/reports/history/forms/UserHistoryForm.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import ReportsServices from "@/services/ReportsServices";
import RoleServices from "@/services/RoleServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "UsersPage",

  components: {
    UsersForm,
    Table,
    TitleButton,
  },
  setup() {
    //------SERVICES
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _ReportsServices = new ReportsServices();
    const _RoleService = new RoleServices();

    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------VARIABLES
    let listUsers = ref([]);
    let listLider = ref([]);
    let listGroups = ref([]);
    let listProfiles = ref([]);
    let userLevels = ref([]);
    let userHistory = ref([]);
    let flag = ref();

    /* CONSTANTS */

    const columnsTable = [
      {
        name: "id",
        label: "ID",
        field: (row) => row.id,
        align: "center",
        sortable: true,
      },
      {
        name: "user_name",
        label: "Nome",
        field: (row) => row.user_name,
        align: "center",
      },
      {
        name: "login_email",
        label: "Email ou matrícula",
        field: (row) => row.login_email,
        align: "center",
      },
      {
        name: "groups_name",
        label: "Grupo",
        field: (row) => row.groups_name,
        align: "center",
      },
      {
        name: "user_type",
        label: "Cargo",
        align: "center",
        field: (row) => row.user_type,
      },
      {
        name: "lider_name",
        label: "Líder",
        field: (row) => row.lider_name,
        align: "center",
      },
      {
        name: "level_name",
        label: "Nível",
        align: "center",
        field: (row) => row.level_name,
        format: (row) => (row.length > 1 ? `${row}` : `Sem nível`),
      },
    ];

    onMounted(() => {
      _getAllGroups();
      _getAllUsers();
      _listAllRoles();
      _listAllLevels();
    });

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listLider.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            } else {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _listAllRoles() {
      _RoleService
        .showRoles()
        .then((response) => {
          response.data.map((item) => {
            listProfiles.value.push({
              label: item.name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _listAllLevels() {
      await _UserService
        .userLevels()
        .then((response) => {
          response.data.map((item) => {
            userLevels.value.push({
              label: item.class_name + " " + item.level_name,
              value: item.level_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("Cargo", form.profile);
      formData.append("Lider", form.leadership);
      formData.append("Level", form.level);
      formData.append("Group_id", form.Groups);

      await _UserService
        .historyUser(formData)
        .then((response) => {
          userHistory.value = response.data;
          if (userHistory.value.length === 0) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          }
        })
        .catch((err) => {
          Alerts.alertOf(
            "Ocorreu um erro na busca, por favor tente mais tarde"
          );
        });
    }

    async function generateExcel(groupId) {
      await _ReportsServices
        .reportUsersByGroup(groupId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "usuarios.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          Alerts.alertOf("Erro ao gerar relatório, por favor tente mais tarde");
        });
    }

    return {
      //------VARIABLES
      listUsers,
      listGroups,
      listProfiles,
      userLevels,
      userHistory,
      columnsTable,
      flag,
      listLider,
      //------FUNCTIONS
      validateForm,
      generateExcel,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}

.table-user {
  margin-top: 5vh;
}
</style>
