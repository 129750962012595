<template>
  <div>
    <TitleButton
      @stateButton="
        (isShowFilter) => {
          flag = isShowFilter;
        }
      "
    />
    <LightningTestForm
      :listTitle="listTitle"
      :listCreatorPost="listTutor"
      :listStutendInTrail="listStutendInTrail"
      :listRelatedPost="listPost"
      @getCourseId="(course) => getCourse(course)"
      @sendForm="(form) => validateForm(form)"
      v-if="flag"
    />
    <div class="table-lightning-test">
      <h5 class="q-mt-sm q-ml-xl">Histórico de teste relâmpago</h5>
      <Table
        class="full-height full-width"
        :addEdit="false"
        :addRemove="false"
        :list="row"
        :columns="columnsTable"
      >
      </Table>
    </div>
  </div>
</template>

<script>
//----COMPONENTS
import LightningTestForm from "@/components/reports/history/forms/LightningTestHistoryForm.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";

//----SERVICES
import UserServices from "@/services/UserServices";
import TrailDataServices from "@/services/TrailDataServices";
import ReportsServices from "@/services/ReportsServices";
import PostDataServices from "@/services/PostDataServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

//----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "LightningTestPage",

  components: {
    LightningTestForm,
    TitleButton,
    Table,
  },
  setup() {
    //------SERVICES
    const _UserService = new UserServices();
    const _trailDataServices = new TrailDataServices();
    const _ReportsServices = new ReportsServices();
    const _PostDataServices = new PostDataServices();
    const store = useStore();
    const company_id = store.state.userdata.company_id;
    //----UTILS
    const Alerts = new AlertsClass();

    //------CONSTATNS
    const $q = new useQuasar();

    const columnsTable = [
      {
        name: "id",
        label: "ID",
        align: "center",
        field: (row) => row.id,
        sortable: true,
      },
      {
        name: "created_name",
        label: "Criado por",
        align: "center",
        field: (row) => row.created_name,
      },
      {
        name: "created_at",
        label: "Criado em",
        align: "center",
        field: (row) => row.created_at,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
      },
      {
        name: "post_title",
        label: "Posts relacionados",
        align: "center",
        field: (row) => row.post_title,
      },
      {
        name: "title",
        label: "Título",
        align: "center",
        field: (row) => row.title,
      },
      {
        name: "number_users",
        label: "Usuários",
        align: "center",
        field: (row) => row.number_users,
      },
      {
        name: "adherence",
        label: "Aderência",
        align: "center",
        field: (row) => row.adherence,
        format: (row) => `${row.toFixed(2)}%`,
      },
      {
        name: "point_value",
        label: "Moedas",
        align: "center",
        field: (row) => row.point_value,
      },
    ];

    //------VARIABLES
    let listTutor = ref([]);
    let listCourse = ref([]);
    let listTitle = ref([]);
    let listPost = ref([]);
    let listTrail = ref([]);
    let listStutendInTrail = ref([]);
    let listCategories = ref([]);
    let flag = ref(false);
    let row = ref([]);

    onMounted(() => {
      _getAllTutors();
      _getAllCourses();
      _getAllCategories();
      _getAllLightningTest();
      _getAllPost();
    });

    async function _getAllTutors() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2) {
              listTutor.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCourses() {
      _trailDataServices
        .getAllCourses()
        .then((response) => {
          response.data.map((item) => {
            listCourse.value.push({
              label: item.title,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllTrails(courseId) {
      _trailDataServices
        .getAllTraining(courseId)
        .then((response) => {
          response.data.map((item) => {
            let trail = {
              label: item.training_title,
              value: item.id,
            };

            if (listTrail.value.length < 1) {
              listTrail.value.push(trail);
            } else if (_isInList(listTrail.value, trail)) {
              listTrail.value.push(trail);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function _isInList(List, newItem) {
      let isEqual = true;
      List.forEach((item) => {
        if (item.value == newItem.value) {
          isEqual = false;
        }
      });

      return isEqual;
    }

    async function _getAllStudentInTrails(courseId) {
      _trailDataServices
        .getStudentsInTrail(courseId)
        .then((response) => {
          response.data.map((item) => {
            let user = {
              label: item.first_name + " " + item.last_name,
              value: item.user_id,
            };
            if (listStutendInTrail.value.length < 1) {
              listStutendInTrail.value.push(user);
            } else if (_isInList(listStutendInTrail.value, user)) {
              listStutendInTrail.value.push(user);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      _trailDataServices
        .getCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            // if (item.length < 1) {
            // }

            let trail = {
              label: item.name,
              value: item.id,
            };
            listCategories.value.push(trail);
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function getCourse(courses) {
      courses.forEach(async (course) => {
        await _getAllTrails(course);
        await _getAllStudentInTrails(course);
      });
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("DateStart", form.formData.Date_Start);
      formData.append("DateEnd", form.formData.Date_End);

      if (form.formData.Title != null) {
        formData.append("Title", form.formData.Title);
      }
      if (form.formData.Related_Post != null) {
        formData.append("Post_Related", form.formData.Related_Post);
      }

      if (form.formData.Users != null) {
        formData.append("User_Created", form.formData.Users);
      }

      await _PostDataServices
        .historyLightningTest(formData)
        .then((response) => {
          row.value = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function _getAllLightningTest() {
      await _PostDataServices
        .getAllLightningTest()
        .then((response) => {
          response.data.forEach((element) => {
            let post = {
              label: element.title,
              value: element.id,
            };
            listTitle.value.push(post);
            console.log(listTitle.value);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    async function _getAllPost() {
      const PAGE_SIZE = 50;
      const PAGE_NUMBER = 1;
      await _PostDataServices
        .getAll(PAGE_NUMBER, PAGE_SIZE)
        .then((response) => {
          response.data.data.forEach((element) => {
            let post = {
              label: element.title,
              value: element.postId,
            };
            listPost.value.push(post);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      //------VARIABLES
      listTutor,
      listCourse,
      listTitle,
      listPost,
      listTrail,
      listStutendInTrail,
      listCategories,
      flag,
      row,
      //------FUNCTIONS
      validateForm,
      getCourse,

      //-------CONSTANTS
      columnsTable,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}

.table-lightning-test {
  margin-top: 5vh;
}
</style>
