<template>
  <div class="container">
    <div class="header">
      <!--       <q-btn
        color="default-pink"
        icon="delete"
        rounded
        no-caps
        flat
        label="Limpar filtros"
        @click="clearFilter"
      /> -->
    </div>
    <div class="form-report">
      <div class="select-inputs">
        <div>
          <label for="leader">Líder</label>
          <Select
            :listOption="listUsers"
            @selectValue="(val) => (formMoodReport.leaders = val)"
            :label="'Líderes'"
          />
        </div>
        <div>
          <label for="group">Grupos</label>
          <Select
            :listOption="listGroups"
            @selectValue="(val) => (formMoodReport.groups = val)"
            :label="'Grupos'"
          />
        </div>
      </div>
      <div class="radio-inputs">
        <h2>Período</h2>
        <div class="q-gutter-xl row wrap">
          <div v-for="(item, index) in period" :key="index">
            <q-radio
              v-model="periodSelect"
              :val="item.label"
              :label="item.label"
              color="default-pink"
              @update:model-value="(period) => setDataByPeriod(item)"
            />
          </div>
        </div>
      </div>
      <div class="custom-date">
        <div>
          <label for="from">De</label>
          <q-input
            v-model="formMoodReport.Date_Start"
            id="from"
            dense
            type="date"
            outlined
            bg-color="grey-3"
          />
        </div>
        <div>
          <label for="from">Até</label>
          <q-input
            v-model="formMoodReport.Date_End"
            id="from"
            dense
            type="date"
            outlined
            bg-color="grey-3"
          />
        </div>
      </div>
    </div>
    <div class="self-end">
      <q-btn
        color="default-pink"
        size="md"
        class="q-mt-md button"
        @click="generateReport"
      >
        <q-img class="q-mr-sm icon-btn" :src="filterimg" width="18px" />
        <label for="" class="q-mr-sm cursor-pointer">Filtro</label>
      </q-btn>
    </div>
  </div>
</template>

<script>
import UserServices from "@/services/UserServices";
import GroupServices from "@/services/GroupServices.js";

import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import Reports from "@/assets/icons/menu/Reports.vue";
import Select from "@/components/reports/inputs/Select.vue";

import filterimg from "@/assets/icons/filter.svg";

export default {
  name: "MoodHistoryForm",
  components: {
    Reports,
    Select,
  },
  emits: ["tableData"],
  setup(props, { emit }) {
    const _UserServices = new UserServices();
    const _GroupServices = new GroupServices();
    const router = new useRouter();

    let formMoodReport = reactive({
      leaders: [],
      groups: [],
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
    });

    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },

      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);

    const periodSelect = ref(null);
    const listUsers = ref([]);
    const listGroups = ref([]);
    const historyMood = ref([]);

    async function getAllUsers() {
      await _UserServices
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllGroups() {
      _GroupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    /*     function clearFilter() {
      formMoodReport.leaders = [];
      formMoodReport.groups = [];
      formMoodReport.Date_Start = new Date().toISOString().substr(0, 10);
      formMoodReport.Date_End = new Date().toISOString().substr(0, 10);
      console.log(formMoodReport);
    } */

    function generateReport() {
      const formData = new FormData();

      if (formMoodReport.Date_Start != null || undefined) {
        formData.append("DateInitial", formMoodReport.Date_Start);
      }

      if (formMoodReport.Date_End != null || undefined) {
        formData.append("DateFinal", formMoodReport.Date_End);
      }

      if (formMoodReport.groups.length != 0) {
        formMoodReport.groups.forEach((group) => {
          formData.append("Group", group);
        });
      }

      if (formMoodReport.leaders.length != 0) {
        formMoodReport.leaders.forEach((leader) => {
          formData.append("Leader", leader);
        });
      }

      _UserServices
        .moodHistory(formData)
        .then((response) => {
          if (response.data.length !== 0) {
            historyMood.value = response.data;
            emit("tableData", historyMood.value);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      /* emit("tableData", formMoodReport); */
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formMoodReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formMoodReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formMoodReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formMoodReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formMoodReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formMoodReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formMoodReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formMoodReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formMoodReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formMoodReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    onMounted(() => {
      getAllUsers();
      getAllGroups();
    });
    return {
      period,
      periodSelect,
      listUsers,
      listGroups,
      formMoodReport,
      generateReport,
      setDataByPeriod,
      filterimg,
    };
  },
};
</script>

<style scoped>
.container {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-report {
  margin-top: 2.125rem;
  display: flex;
  flex-direction: column;
}

.form-report label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.25px;
  color: #141519;
  margin-block: 0.25rem;
}

.select-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-inputs div + div {
  margin-top: 20px;
}

.radio-inputs {
  margin-top: 1.75rem;
}

.icon-btn {
  filter: brightness(2.5);
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 1.125rem;
}

.custom-date {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
}

.custom-date label {
  margin-bottom: 0.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #c4c4c4;
}

.button {
  width: 204px;
  height: 42px;
}

@media (min-width: 600px) {
  .select-inputs {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .select-inputs div {
    width: calc(50% - 14px);
  }

  .select-inputs div + div {
    margin-top: 0;
  }

  .custom-date {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .custom-date div {
    width: calc(187px);
  }
}
</style>
