<template>
  <div>
    <TitleButton
      @stateButton="
        (isShowFilter) => {
          flag = isShowFilter;
        }
      "
    />
    <WikiForm
      :listGroups="listGroups"
      :listcategory="listCategories"
      :listCreatorWiki="listCreatorPost"
      :getWikiByTitle="getWikiByTitle"
      @sendForm="(form) => validateForm(form)"
      v-if="flag"
    />
    <div class="table-wiki">
      <h5 class="q-mt-sm q-ml-xl">Histórico de wikis</h5>
      <Table
        class="full-height full-width"
        :list="row"
        :columns="columnsTable"
        :addEdit="false"
        :addRemove="false"
      >
      </Table>
    </div>
  </div>
</template>

<script>
//----COMPONENTS
import WikiForm from "@/components/reports/history/forms/WikiHistoryForm.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import TitleButton from "@/components/reports/buttons/titleButton.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import WikiDataServices from "@/services/WikiDataServices";
import ReportsServices from "@/services/ReportsServices";
// import PostDataServices from "@/services/PostDataServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "FeedPage",

  components: {
    WikiForm,
    Table,
    TitleButton,
  },
  setup() {
    //------SERVICES
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _wikiDataServices = new WikiDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------CONSTATNS
    const $q = new useQuasar();
    const columnsTable = [
      {
        name: "wiki_id",
        label: "ID",
        align: "center",
        sortable: true,
        field: (row) => row.wiki_id,
      },
      {
        name: "created_name",
        label: "Criado por",
        align: "center",
        field: (row) => row.created_name,
      },
      {
        name: "datetime_start",
        label: "Postado em",
        align: "center",
        field: (row) => row.datetime_start,
        format: (row) => `${row.slice(0, 10).split("-").reverse().join("/")}`,
      },

      {
        name: "wiki_groups",
        label: "Grupo",
        align: "center",
        field: (row) => row.wiki_groups,
      },
      {
        name: "title",
        label: "Título",
        align: "center",
        field: (row) => row.title,
      },
      {
        name: "category_name",
        label: "Categoria",
        align: "center",
        field: (row) => row.category_name,
      },
    ];

    //------VARIABLES
    let listCreatorPost = ref([]);
    let listProfiles = ref([]);
    let listGroups = ref([]);
    let listCategories = ref([]);
    let row = ref([]);
    let flag = ref();

    onMounted(() => {
      _getAllGroups();
      _getAllUsers();
      _getAllCategories();
    });

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2 || item.role_id == 1) {
              listCreatorPost.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCategories() {
      await _wikiDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.name,
              value: item.id,
            });
          });
        });
    }

    async function getWikiByTitle(title) {
      return await _wikiDataServices.getForTitle(title);
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("DateInitial", form.Date_Start);
      formData.append("DateFinal", form.Date_End);

      if (form.Category != null) {
        formData.append("Category_id", form.Category);
      }
      if (form.Groups != null) {
        formData.append("Group_id", form.Groups);
      }
      if (form.Users != null) {
        formData.append("User_created", form.Users);
      }

      await _wikiDataServices
        .wikiHistory(formData)
        .then((reponse) => {
          row.value = reponse.data;
          if (row.value.length === 0) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          }
        })
        .catch((error) => {
          Alerts.alertOf(
            "Ocorreu um erro na busca, por favor tente mais tarde"
          );
        });
    }

    return {
      //------VARIABLES
      listProfiles,
      listGroups,
      listCategories,
      listCreatorPost,
      row,
      columnsTable,
      flag,

      //------FUNCTIONS
      getWikiByTitle,
      validateForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}

.table-wiki {
  margin-top: 5vh;
}
</style>
