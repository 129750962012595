<template>
  <div>
    <div class="row items-center q-px-md">
      <q-btn
        round
        flat
        :icon="isShowFilter ? 'expand_more' : 'chevron_right'"
        color="default-pink"
        @click="
          () => {
            isShowFilter = !isShowFilter;
            $emit('stateButton', isShowFilter);
          }
        "
      />
      <h5>Filtros</h5>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "TitleButton",
  setup() {
    let isShowFilter = ref(false);
    return {
      isShowFilter,
    };
  },
  emits: ["stateButton"],
};
</script>

<style></style>
